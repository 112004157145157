import { FaSearch } from "react-icons/fa";
import {  ADD_TO_CART, CHANGE_SELECTED_PRODUCTS_DATE, UPDATE_CUSTOMER_FROM_URL, SET_SORT_OPTION, GET_LANDING_PRODUCTS_ERROR, SET_ORDER_OPTION, UPDATE_LANDING_PRODUCTS, UPDATE_LANDING_FILTERS, RESET_LANDING_PRODUCTS, GET_LANDING_PRODUCTS, ADD_PRODUCT_SIDEBAR, SET_QUESTIONS_CHECKED, START_CHECK_VALIDATION, GET_PURCHASE_DATE, REINITIALIZE_PRODUCTS, CLEAR_CART, UPDATE_ADDRESS, INCREASE_CHECKED_QUESTIONS, DECREASE_CHECKED_QUESTIONS,DELETE_STRIPE_CLIENT_SECRET, SET_PAYMENT_DAY, SET_BIRTHDAY, FAILED_BANK_VALIDATION_REQUEST, CHECK_BANK_DETAILS, UPDATE_PERSONAL_FUNDING_DETAILS, CHANGE_STEP_VALUE, CHECK_QUESTION, COMPLETE_ORDER_BEGIN, DECREASE_STEP, DELETE_EXISTING_STRIPE_DATA, DESELECT_PRODUCT, GET_PRODUCTS_BEGIN, GET_PRODUCTS_ERROR, GET_PRODUCTS_SUCCESS, GET_PURCHASE_CODE, GET_QUESTIONS_BEGIN, GET_QUESTIONS_SUCCESS, INCREASE_STEP, REMOVE_CART_ITEM, SEARCH_POSTCODE_IN_PROGRESS, SEARCH_REGNO_ERROR, SEARCH_REG_NO_IN_PROGRESS, SET_ADDRESS_DETAILS, SET_FETCHED_VEHICLE_DETAILS, SET_TYPE_OF_PAYMENT, UNCHECK_ALL_QUESTIONS, UPDATE_FILTERS, UPDATE_HIRE_AND_REWARD_QUESTIONS, UPDATE_HIRE_REWARD_QUESTIONS, UPDATE_IDS_ARRAY, UPDATE_QUOTE_ID, UPDATE_REGISTRATION_DATE, SET_FIN_IDD_RESULT, SET_FIN_COMPLAINTS, SET_FIN_CANCELLATION } from "../actions/actions";
import { groupByOrigin } from "../utils/custom-functions";
import { arrayOfOrigins } from "../utils/informations";
import moment from "moment";
const checkout_reducer_v2 = (state, action) => {

  if (action.type === UPDATE_FILTERS) {
    const { value, name } = action.payload;
    return {
      ...state,
      customerData: { ...state.customerData, [name]: value },
    };
  }
  if (action.type === UPDATE_HIRE_AND_REWARD_QUESTIONS) {
    const { checked, name } = action.payload;
    return {
      ...state,
      hireRewardQuestions: { ...state.hireRewardQuestions, [name]: checked },
    };
  }
  if(action.type === RESET_LANDING_PRODUCTS){

    return { ...state, landingProducts: [], loading: true, showLandingFilters: true};
  }
  if(action.type === GET_LANDING_PRODUCTS_ERROR){

    return { ...state, landingProductsError: action.payload};
  }
  if(action.type === GET_LANDING_PRODUCTS){

    let newArray = [...state.landingProducts, ...action.payload];

    let optionsIndex = {

      "claim_limit": 4,
      "rating": 5,
      "claim_excess": 6,
      "month_of_cover": 3,
      "labour_rate": 7
    }

    if (state.orderOption === "asc") {

      if (state.sortOption.key === "price") {
        newArray.sort(function (a, b) {

          return a.Price - b.Price;
        })
      }

      else {
        newArray.sort(function (a, b) {



          if (a.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]] === "NIL" && b.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]] === "NIL") {
            return 0;
          } else if (a.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]] === "NIL") {
            return 1;
          } else if (b.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]] === "NIL") {
            return -1;
          } else {
            return a.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]] - b.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]];
          }



        })
      }
    }
    else {

      if (state.sortOption.key === "price") {
        newArray.sort(function (a, b) {

          return b.Price - a.Price;
        })
      }
      else {
        newArray.sort(function (a, b) {

          if (a.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]] === "NIL" && b.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]] === "NIL") {
            return 0;
          } else if (a.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]] === "NIL") {
            return 1;
          } else if (b.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]] === "NIL") {
            return -1;
          } else {
            return b.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]] - a.CodeTemplate.split("-")[optionsIndex[state.sortOption.key]];
          }
        })
      }

    }

    return { ...state, landingProducts: newArray, landingProductsImmutable: [...state.landingProducts, ...action.payload], loading: false};
  }
  //fetch products action if the user already completed the form from homepage

  if(action.type === GET_PRODUCTS_BEGIN) {
    return {...state, loading:true}
  }

  if(action.type === SET_SORT_OPTION){

    const { value } = action.payload;

    return {...state, sortOption: {key: value}};
  }
  

  if(action.type === SET_ORDER_OPTION){

    const { value } = action.payload;

    return {...state, orderOption: value}
  }
  if(action.type === UPDATE_CUSTOMER_FROM_URL){

    const {regNo, mileage, vehicleValue, email, hireRewardParam, emailReceiving} = action.payload;

    let boolHireReward = hireRewardParam === "true" ? true : false;
    let boolEmailReceiving = emailReceiving === "true" ? true : false;


    return {...state, customerData: {...state.customerData, vehicleValue: vehicleValue, mileage: mileage, regNo: regNo, email: email}, 
                hireRewardQuestions: {...state.hireRewardQuestions, firstQuestion: boolHireReward, emailReceiving: boolEmailReceiving}}
  }
  if(action.type === UPDATE_LANDING_PRODUCTS){

    const {productsList} = action.payload;

    return {...state, landingProducts: productsList, loading: false}
  }
  if(action.type === REINITIALIZE_PRODUCTS){

    return {...state, products: [], quoteId: 0, groupedByOriginProducts: []};
  }
  if(action.type === GET_PRODUCTS_SUCCESS) {
    const {products, quoteId} = action.payload;

    const removeUnknownOrigin = products ? products.filter((item) => arrayOfOrigins.indexOf(item.Origin) !== -1) : []
  

    return {...state, removeUnknownOrigin, quoteId, loading: false, products, groupedByOriginProducts:groupByOrigin(removeUnknownOrigin)}
  }


  if(action.type === GET_PRODUCTS_ERROR) {
    return {...state, loading: false}
  }

  //search vehicle details when entering vehicle-checkout page in case the user already completed the form from homepage 

  if(action.type === INCREASE_CHECKED_QUESTIONS){

    return {...state, questionChecked: state.questionChecked + 1};
  }
  if(action.type === DECREASE_CHECKED_QUESTIONS){
    
    return {...state, questionChecked: 0};
  }
  if(action.type === SET_QUESTIONS_CHECKED){

    return {...state, questionChecked: action.payload}
  }
  if(action.type === SET_FETCHED_VEHICLE_DETAILS) {
    const {VehicleMake, VehicleModel, TyreSizeType, EngineCC, dateSubstring, DriveAxle} = action.payload 
    return {...state, landingProductsFitlers: {...state.landingProductsFitlers, DriveTrain: DriveAxle},
                      customerData:{...state.customerData, vehicleMake: VehicleMake, vehicleModel: VehicleModel, tyresType:TyreSizeType, engineCC: EngineCC ? EngineCC : "0", registrationDate: new Date(dateSubstring) }, error:false, displayEngineAndDateFlag: !EngineCC || !dateSubstring ?  true : false, displaySidebar: true}
  }

  if(action.type === SEARCH_REGNO_ERROR) {
    return {...state, error:true, loading:false}
  }

  if(action.type === SEARCH_REG_NO_IN_PROGRESS){
    return {...state, searchRegNoInProgress: action.payload}
  }

  if(action.type ===  START_CHECK_VALIDATION) {
    
    return {...state, directDebitLoading: true }
  }
  if(action.type === FAILED_BANK_VALIDATION_REQUEST) {

    return {...state, directDebitLoading:false, directDebitError:true}
  }
  if(action.type ===  SET_FIN_IDD_RESULT) {
    return {...state, finIDDfetchResult: action.payload}
  }
  if(action.type ===  SET_FIN_COMPLAINTS) {
    return {...state, finComplaints: action.payload}
  }
  if(action.type ===  SET_FIN_CANCELLATION) {
    return {...state, finCancellation: action.payload}
  }
  if(action.type === SET_PAYMENT_DAY) {

    return {...state,directDebitDetails:{...state.directDebitDetails, preferredPaymentDateUnformatted: action.payload, preferredPaymentDate:  moment(action.payload).format('DD/MM/YYYY')} }
  }
  if(action.type === SET_BIRTHDAY) {
    
    return {...state, dateOfBirthUnformatted: action.payload, dateOfBirth:  moment(action.payload).format('DD/MM/YYYY')}
  }
  if(action.type ===  CHECK_BANK_DETAILS) {
  
    const {code, result} = action.payload
   
    return {...state, directDebitDetails:{...state.directDebitDetails, bankName: result.Bank, bankPostcode: result.ContactPostcode, bankAddress1:result.ContactAddressLine1, bankAddress2:result.ContactAddressLine2,bankTown: result.ContactPostTown ? result.ContactPostTown : "-" }, directDebitLoading: false, bankValidationCode:code }
    
  }
  //add to cart function

  if (action.type === ADD_TO_CART) {

    
    const { product } = action.payload

    if (product) {

     

    const tempItem = state.cart?.find(item => item.Origin === product.Origin) // check if an item with the same origin exists in the cart
    const rtiItem = state.cart?.find(item => item.Origin === "Return to Invoice") 
    const gapItem = state.cart?.find(item => item.Origin === "Finance Gap")

    

      
  if (tempItem) {
    const tempCart = state.cart.map(cartItem => {
      if (cartItem.Origin === product.Origin) {
        let replace = state.cart.findIndex(
          item => item.Origin === cartItem.Origin
        )

        
        let newItem
        if (replace !== -1) {
          
          newItem = state.cart[replace] = product
        }

        return { ...newItem }
      } else {
        return cartItem
      }

      
    })
    return { ...state, cart: tempCart }

        
  } 
  
  if(product.Origin === 'Return to Invoice' && gapItem) {
       let replace = state.cart.findIndex(item => item.Origin === "Finance Gap")
       if (replace !== -1) {
         state.cart[replace] = product
       }
       return {...state}
      } if(product.Origin === "Finance Gap" &&rtiItem) {
       let replace = state.cart.findIndex(item => item.Origin === 'Return to Invoice')
       if (replace !== -1) {
         state.cart[replace] = product
       }
       return {...state}
      } 
      
      else {

     

        const newItem ={
          Description: product.Description,
          Discount: product.Discount,
          KeyFacts: product.KeyFacts,
          Origin: product.Origin,
          PPPId: product.PPPId,
          Price: product.Price,
          PriceRRP: product.PriceRRP,
          ProducGroupId: product.ProducGroupId,
          ProducGroupTitle: product.ProducGroupTitle,
          Title: product.Title,
          claim_limit: product.claim_limit,
          months_of_cover: product.months_of_cover,
        }

       

        return { ...state, cart: [...state.cart, newItem] }
      }

      
    } else {
      return { ...state, purchaseError: true }
    }

    
  }

  //remove insurance from cart if the user changes his mind and closes the product component after he already added that product in the cart

  if(action.type === DESELECT_PRODUCT) {
    const filterCart = state.cart.filter((item) => item.Origin !== action.payload)
    return {...state, cart:filterCart}
  }


  //update ids array every time cart changes

  if(action.type === UPDATE_IDS_ARRAY ) {
    const idsArray = state.cart.map((item) => item.PPPId)
    const selectedProductsUnformatted = state.cart.map((cartItem) => {
    return {
        Title: cartItem.Title,
        PPPId: cartItem.PPPId,
        StartDate: new Date(),
        Origin: cartItem.Origin,
        Price: cartItem.Price,
        months_of_cover: cartItem.months_of_cover,
        claim_limit:cartItem.claim_limit
    }

    }
    )
     const selectedProducts = selectedProductsUnformatted.map((item) => {
    return {
      PPPId: item.PPPId, 
      StartDate: moment(item.StartDate).format('DD/MM/YYYY')
    }
  })

    let totalCost = 0
    state.cart.map((item) => totalCost += item.Price)
    return {...state, ids:idsArray, selectedProductsUnformattedDate:selectedProductsUnformatted, totalCost: totalCost, selectedProducts}
  }  

  //action for changing the date in date picker 

 if(action.type === CHANGE_SELECTED_PRODUCTS_DATE) {
    const {date, id} = action.payload;
    const selectedProducts = state.selectedProductsUnformattedDate
    let updateDateIndex = selectedProducts.findIndex(item => item.PPPId === id)
    selectedProducts[updateDateIndex].StartDate = date
  
   const formattedDate =  selectedProducts.map((object) => {
      const {StartDate, PPPId} = object;
     
      return {PPPId, StartDate: moment(StartDate).format('DD/MM/YYYY')}
    })
    return {...state, selectedProductsUnformattedDate: [...state.selectedProductsUnformattedDate], selectedProducts: formattedDate }
  }

  if (action.type === REMOVE_CART_ITEM) {
    const newCart = state.cart.filter(item => item.PPPId !== action.payload)
    // const filterIds = state.ids.filter(item => item !== action.payload)
    // const filterSelectedProducts = state.SelectedProducts.filter(item => item.PPPId !== action.payload)
    return { ...state, cart: newCart }
  }

  //increase/decrese step in checkout process

   if (action.type === INCREASE_STEP) {
    return { ...state, checkoutStep: state.checkoutStep + 1 }
  }
  if (action.type === DECREASE_STEP) {
    return { ...state, checkoutStep: state.checkoutStep  === 0 ? 0: state.checkoutStep - 1 }
  }

  if(action.type === UPDATE_LANDING_FILTERS){

    const {value, name} = action.payload;

    return {...state, landingProductsFitlers: {...state.landingProductsFitlers, [name]: value}}
  }
  //search postcode

   if(action.type === SEARCH_POSTCODE_IN_PROGRESS){
    return {...state, searchPostcodeInProgress: action.payload}
  }
  if(action.type === SET_ADDRESS_DETAILS){

    let addressList = [];
    const {address} = action.payload

    if(address){
      addressList = address;
    }


    return {...state, customerData:{...state.customerData, addressList: addressList} }
  }

  //update quoteId after getQuote fetch with customer details

  if(action.type === UPDATE_QUOTE_ID) {
    return {...state, quoteId: action.payload}
  }

  //check demands and needs fetch actions

  if(action.type === GET_QUESTIONS_BEGIN) {
    return {...state, isQuestionsLoading: true}
  }


  if(action.type === GET_QUESTIONS_SUCCESS) {
    const {removeInvert, premiumFundingDetails, co2Emission} = action.payload
   
    return {...state, questions: removeInvert, premiumFundingDetails, co2Emission, isQuestionsLoading:false, checkedList: state.cart.length !== 0 ? removeInvert.map((option) => ({
            ...option,
            checked: false
        })) : []}
  }

  if(action.type === CHECK_QUESTION) {
    return {...state, checkedList: action.payload}
  }
  if (action.type === UPDATE_ADDRESS ) {
    const  {value, town, address, postcode}  = action.payload
    
    return {
      ...state,
        
       
        customerData:{
          ...state.customerData,
          town,
          postcode,
          address: value,
        }
      
    }
  }
  //choose type of payment
  if(action.type === SET_TYPE_OF_PAYMENT) {

    return {...state, typeOfPayment: action.payload, selectTypeOfPaymentFlag:true}
  }
  if (action.type === CLEAR_CART) {
    return { ...state, cart: [], ids: [], questions:[] }
  }
  if(action.type === UPDATE_PERSONAL_FUNDING_DETAILS) {
    const {value, name} = action.payload
    return {...state,directDebitDetails:{...state.directDebitDetails, [name]:value} }
  }
  //changed checkout step value
  if(action.type === CHANGE_STEP_VALUE) {
    return {...state, checkoutStep: action.payload}
  }

  if(action.type === ADD_PRODUCT_SIDEBAR){

    let {productOrigin} = action.payload;

    return {...state, addProduct: productOrigin}

  }
  if(action.type === UNCHECK_ALL_QUESTIONS) {
    return {...state, checkedList: state.cart.length !== 0? state.questions.map((option) => ({
            ...option,
            checked: false
        })) : []}
  }

  
  if(action.type === DELETE_EXISTING_STRIPE_DATA) {
    return {...state, stripeTokenId:'', stripeStatusCode:'', datePickerFlag:false}
  }

  if(action.type === GET_PURCHASE_DATE) {
   
    return {...state, customerData:{...state.customerData, dateOfPurchase:action.payload}}
  }

  if(action.type === COMPLETE_ORDER_BEGIN) {
    return {...state, purchaseLoading: true}
  }

  if(action.type === GET_PURCHASE_CODE) {
      const {purchaseCode, code, stripeClientSecret} = action.payload
      return {...state, purchaseCode: purchaseCode, stripeStatusCode:code, stripeClientSecret, datePickerFlag:true, purchaseLoading: false}
  }

  if(action.type === DELETE_STRIPE_CLIENT_SECRET) {
    return { ...state, stripeClientSecret: undefined}
  }
  //update registration date from vehicle form

  if(action.type === UPDATE_REGISTRATION_DATE) {
    return {...state, customerData:{...state.customerData, registrationDate: action.payload}}
  }


throw new Error(`No Matching "${action.type}" - action type`)
}

export default checkout_reducer_v2