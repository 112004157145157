import React, { useContext, useEffect, useReducer } from 'react';
import { GET_PRODUCTS_BEGIN, GET_PRODUCTS_ERROR, RESET_LANDING_PRODUCTS, SET_SORT_OPTION, GET_LANDING_PRODUCTS_ERROR, SET_ORDER_OPTION, UPDATE_LANDING_PRODUCTS, UPDATE_LANDING_FILTERS, UPDATE_CUSTOMER_FROM_URL, SET_FIN_CANCELLATION, GET_LANDING_PRODUCTS, SET_QUESTIONS_CHECKED, SET_FIN_COMPLAINTS, ADD_PRODUCT_SIDEBAR, SET_FIN_IDD_RESULT, GET_PRODUCTS_SUCCESS, REINITIALIZE_PRODUCTS, UPDATE_ADDRESS, CLEAR_CART, DECREASE_CHECKED_QUESTIONS, GET_REGISTRATION_DATE, GET_PURCHASE_DATE, INCREASE_CHECKED_QUESTIONS, DELETE_STRIPE_CLIENT_SECRET, SET_PAYMENT_DAY, SET_BIRTHDAY, START_CHECK_VALIDATION, CHECK_BANK_DETAILS, FAILED_BANK_VALIDATION_REQUEST, GET_QUOTE_ID, UPDATE_PERSONAL_FUNDING_DETAILS, SEARCH, UPDATE_FILTERS, SEARCH_REGNO_ERROR, SET_FETCHED_VEHICLE_DETAILS, SEARCH_REG_NO_IN_PROGRESS, ADD_TO_CART, REMOVE_CART_ITEM, DESELECT_PRODUCT, UPDATE_IDS_ARRAY, CHANGE_SELECTED_PRODUCTS_DATE, INCREASE_STEP, DECREASE_STEP, SEARCH_POSTCODE_IN_PROGRESS, SET_ADDRESS_DETAILS, UPDATE_QUOTE_ID, GET_QUESTIONS_BEGIN, GET_QUESTIONS_SUCCESS, CHECK_QUESTION, SET_TYPE_OF_PAYMENT, CHANGE_STEP_VALUE, UNCHECK_ALL_QUESTIONS, UPDATE_HIRE_AND_REWARD_QUESTIONS, DELETE_EXISTING_STRIPE_DATA, COMPLETE_ORDER_BEGIN, GET_PURCHASE_CODE, UPDATE_REGISTRATION_DATE } from '../actions/actions';
import reducer from '../reducers/checkout-reducer-v2'
import moment from "moment";
import { groupByOrigin } from "../utils/custom-functions";
import { arrayOfOrigins } from "../utils/informations";
import { MyTheme } from "../utils/informations";
import { landingProviders } from "../utils/informations";

const NewCheckoutContext = React.createContext();


let formData = {};
let storedHireAndReward = {};
let quoteDetails = {};
let products = [];
let groupedByOriginProducts;
let storedCart = [];
let formStep;
let premiumFundingDetails = {};
let storedIdd;
let questions = [];
let complaints;
let cancellation;

if (typeof window !== "undefined" && window.sessionStorage.getItem("quoteDetails")) {
  quoteDetails = JSON.parse(sessionStorage.getItem("quoteDetails"));
}

if (typeof window !== "undefined" && window.sessionStorage.getItem("formData")) {
  formData = JSON.parse(sessionStorage.getItem("formData"));
}

if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("hireRewardQuestions")
) {
  storedHireAndReward = JSON.parse(
    sessionStorage.getItem("hireRewardQuestions")
  );
}
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("groupedByOriginProducts")
) {
  groupedByOriginProducts = JSON.parse(
    sessionStorage.getItem("groupedByOriginProducts")
  );
}
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("premiumFundingDetails")
) {
  premiumFundingDetails = JSON.parse(
    sessionStorage.getItem("premiumFundingDetails")
  );
}

if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("products")
) {
  products = JSON.parse(
    sessionStorage.getItem("products")
  );
}

if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("questions")
) {
  questions = JSON.parse(
    sessionStorage.getItem("questions")
  );
}

if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("checkoutStep")
) {
  formStep = JSON.parse(
    sessionStorage.getItem("checkoutStep")
  );
}

if (typeof window !== "undefined" && window.sessionStorage.getItem("cart")) {
  storedCart = JSON.parse(sessionStorage.getItem("cart"));
}

const initialState = {
  customerData: {
    mileage: formData?.mileage ? formData?.mileage : quoteDetails.Mileage ? quoteDetails.Mileage : '',
    vehicleValue: formData?.vehicleValue ? formData.vehicleValue : quoteDetails.Price ? quoteDetails.Price : '',
    vehicleMake: formData?.vehicleMake ? formData.vehicleMake : quoteDetails.VehicleMake ? quoteDetails.VehicleMake : '',
    vehicleModel: formData?.vehicleModel ? formData.vehicleModel : quoteDetails.VehicleModel ? quoteDetails.VehicleModel : '',
    engineCC: formData?.engineCC ? formData.engineCC : quoteDetails.EngineCC ? quoteDetails.EngineCC : '',
    regNo: formData?.regNo ? formData?.regNo : quoteDetails?.RegNo ? quoteDetails.RegNo : '',
    categoryType: "Car",
    registrationDate: new Date(),
    dateOfPurchase: new Date(),
    Referral: '',

    firstname: formData?.firstname ? formData.firstname : quoteDetails.FirstName ? quoteDetails.FirstName : '',
    lastname: formData?.lastname ? formData.lastname : quoteDetails.Surname ? quoteDetails.Surname : '',
    email: formData?.email ? formData.email : quoteDetails.Email ? quoteDetails.Email : '',
    address: formData?.address ? formData.address : quoteDetails.Address1 ? quoteDetails.Address1 : '',
    mobile: formData?.mobile ? formData.mobile : quoteDetails.Mobile ? quoteDetails.Mobile : '',
    tyresType: formData?.tyresType ? formData.tyresType : quoteDetails.TyreSizeType ? quoteDetails.TyreSizeType : '',
    postcode: formData?.postcode ? formData.postcode : quoteDetails.Postcode ? quoteDetails.Postcode : '',
    town: formData?.town ? formData.town : quoteDetails.Town ? quoteDetails.Town : '',
    addressList: null,
  },
  landingProductsFitlers: {
    ProductType: 'WRT',
    ClaimLimit: '',
    ClaimExcess: '',
    Term: '',
    LabourRate: '7000',
    DriveTrain: '',
    WearTear: '',
    FullHistory: '',

  },
  addProduct: "",
  searchPostcodeInProgress: "",
  hireRewardQuestions: {
    firstQuestion: Object.keys(storedHireAndReward).length !== 0 ? storedHireAndReward.firstQuestion : 'Yes',
    secondQuestion: Object.keys(storedHireAndReward).length !== 0 ? storedHireAndReward.secondQuestion : false,
    thirdQuestion: Object.keys(storedHireAndReward).length !== 0 ? storedHireAndReward.thirdQuestion : true,
    emailReceiving: Object.keys(storedHireAndReward).length !== 0 ? storedHireAndReward.emailReceiving : null
  },
  directDebitDetails: {
    firstname: "",
    surname: "",
    sortCode: "",
    accountNo: "",
    bankName: "",
    bankPostcode: "",
    bankAddress1: "",
    bankAddress2: "",
    bankTown: "",
    preferredPaymentDateUnformatted: new Date(),
    preferredPaymentDate: moment(new Date()).format("DD/MM/YYYY"),
  },
  landingProducts: null,
  landingProductsImmutable: null,
  sortOption: {
    key: 'price'
  },
  orderOption: "asc",
  showLandingFilters: false,
  dateOfBirthUnformatted: new Date(),
  directDebitLoading: false,
  directDebitError: false,
  bankValidationCode: "",
  products: products ? products.products : [],
  groupedByOriginProducts: groupedByOriginProducts ? groupedByOriginProducts : [],
  quoteId: products ? products.quoteId : '',
  loading: false,
  fetchProductsSuccess: false,
  error: false,
  searchRegNoInProgress: false,
  displaySidebar: false,
  displayEngineAndDateFlag: false,
  cart: storedCart ? storedCart : [],
  ids: [],
  purchaseError: '',
  selectedProductsUnformattedDate: storedCart ? storedCart : [], //in react date picker you must use new Date()
  selectedProducts: storedCart ? storedCart : [], //array for sending to api with date formatted DD/MM/YYYY
  totalCost: 0,
  checkoutStep: formStep ? formStep : 0,
  isQuestionsLoading: false,
  questions: questions ? questions : [],
  checkedList: questions ? questions : [],
  premiumFundingDetails: premiumFundingDetails ? premiumFundingDetails : {},
  co2Emission: '',
  typeOfPayment: 'fullPayment',
  selectTypeOfPaymentFlag: 'ShowPaymentTypeSelector' in premiumFundingDetails ? premiumFundingDetails.ShowPaymentTypeSelector : false, //value set to trigger call to completeQuote
  purchaseCode: '',
  co2Option: false,
  stripeStatusCode: '',
  stripeClientSecret: '',
  stripeTokenId: '',
  landingProductsError: false,
  datePickerFlag: false,
  purchaseLoading: false,
  questionChecked: 0,
  finIDDfetchResult: storedIdd ? storedIdd : "",
  finComplaints: complaints ? complaints : "",
  finCancellation: cancellation ? cancellation : "",


}

export const NewCheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);


  const updateCustomerFromUrl = (regNo, mileage, vehicleValue, email, hireRewardParam, emailReceiving) => {

    dispatch({ type: UPDATE_CUSTOMER_FROM_URL, payload: { regNo, mileage, vehicleValue, email, hireRewardParam, emailReceiving } });

  }

  

  const updateLandingFilters = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    //overwritting the checkbox value as otherwise it will remain on yes all the time (visually)
    if (e.target.type === "checkbox") {
      value = e.target.checked;

      if ( value == false) {
        value = ""
      }
    }

    dispatch({ type: UPDATE_LANDING_FILTERS, payload: { name, value } });
  }

  const updateCustomerDetails = (e) => {
    let name = e.target.name;
    let value = e.target.value;
   // console.log("updateCustomerDetails"+ name + " " + value);
    dispatch({ type: UPDATE_FILTERS, payload: { value, name } });
  };

  const setSortOption = (e) => {

    let value = e.target.value;

    dispatch({ type: SET_SORT_OPTION, payload: { value } });
  }

  const setOrderOption = (e) => {

    let value = e.target.value;

    dispatch({ type: SET_ORDER_OPTION, payload: { value } });

  }
  const deleteClientSecret = () => {
    dispatch({ type: DELETE_STRIPE_CLIENT_SECRET });
  };
  const updateHireRewardQuestions = (e) => {
    let name = e.target.name;
    //console.log("updateHireRewardQuestions:" + e.target.value);
    let checked;
    if (e.target.type === "checkbox") {
    name === 'thirdQuestion' ? checked = !e.target.checked : checked = e.target.checked;
    }
    else{
      checked = e.target.value;
    }
    dispatch({ type: UPDATE_HIRE_AND_REWARD_QUESTIONS, payload: { checked, name } });
  }
  const handleDirectDebit = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    dispatch({
      type: UPDATE_PERSONAL_FUNDING_DETAILS,
      payload: { name, value },
    });
  };
  const addProductSidebar = (productOrigin) => {


    dispatch({ type: ADD_PRODUCT_SIDEBAR, payload: { productOrigin } })
  }

  const sortLandingProducts = (productsList) => {

    dispatch({ type: RESET_LANDING_PRODUCTS })
    dispatch({ type: UPDATE_LANDING_PRODUCTS, payload: { productsList } });
  }
  const getQuoteWithDefaultCustomerValues = async () => {
    dispatch({ type: GET_PRODUCTS_BEGIN });

    let registrationNumber = state.customerData.regNo;
    let vehicleValue = state.customerData.vehicleValue;
    let mileage = state.customerData.mileage;

    let myHeaders = new Headers();
    myHeaders.append("Cookie", "ASP.NET_SessionId=5tnbx0wsv0qzqaf4akfajpps");

    let formdata = new FormData();

    formdata.append(
      "data",
      `{\n'IgnoreFilters':'false',\n'ProviderId':'${process.env.GATSBY_PROVIDER_ID}',\n'RegNo':'${registrationNumber}',\n'Mileage': '${mileage}',\n'Price': '${vehicleValue}',\n'FirstName': 'Test',\n'Surname': 'Surname',\n'Address1': 'test 1234',\n'Email': 'test333@phaeria.com'\n}`
    );

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/GetQuote`,
      requestOptions
    );
    const data = await response.json();
    const { products, quoteId, code, status } = data;

    if (typeof window !== "undefined") {
      sessionStorage.setItem(
        "products",
        JSON.stringify({
          products: state.products,
          quoteId: state.quoteId,
        })
      );
    }

    if(( code && code === "Success")||(status && !code & status === "Success")) {
      // dispatch({ type: SEARCH })
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: { products, quoteId } })
    }
    else 
    {
      dispatch({ type: GET_PRODUCTS_ERROR });
    }
  };

  const updateAddress = (e) => {
    let value = e.target.value;

    const splitAddress = value.split(",");

    const town = splitAddress[splitAddress.length - 2];
    let address = "";
    let postcode = splitAddress[splitAddress.length - 1];

    for (let i = 0; i < splitAddress.length - 2; i++) {
      address += splitAddress[i] + " ";
    }

    dispatch({
      type: UPDATE_ADDRESS,
      payload: { value, town, address, postcode },
    });
  };
  const clearCart = async () => {

    dispatch({ type: CLEAR_CART });

  }
  const getQuoteLandingPage = () => {

    dispatch({ type: RESET_LANDING_PRODUCTS });

    finIdd()

    let myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=5tnbx0wsv0qzqaf4akfajpps");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    let copyArray = [];

    landingProviders.forEach(async element => {

      let formdata = new FormData();
      //andrei: use structuredClone so it doesn't update the state value as I only need it here.
      let eligibility = structuredClone(state.landingProductsFitlers);
      if(eligibility.WearTear == 'Yes')
      {
        eligibility.WearTear = '1';
        eligibility.Batterment = '1';
      }
      else if (eligibility.WearTear == 'No'){
        eligibility.WearTear = '0';
        eligibility.Batterment = '0';
      }
      else{
        eligibility.WearTear = '';
      }
      
      if(eligibility.FullHistory == 'Yes')
      {
        eligibility.FullHistory = '1';
      }
      else{
        eligibility.FullHistory = '0';
      }

      //andrei: in data include the  "MarketingApproved" which is the hireRewardQuestion.emailReceiving
      //console.log("fetch"+ state.customerData.Referral);
      formdata.append(
        "data",
        `{\n'IgnoreFilters':'false',\n'ProviderId':'${element.provider_id}',\n'RegNo':'${state.customerData.regNo.toUpperCase()}',\n'Mileage':'${state.customerData.mileage}',\n'Price':'${state.customerData.vehicleValue}',\n'FirstName':'${state.customerData.firstname ? state.customerData.firstname : "-"}',\n'Surname':'${state.customerData.lastname ? state.customerData.lastname : "-"}',\n'Address1':'${state.customerData.address}',\n'Email':'${state.customerData.email ? state.customerData.email : "TestEmail"}',\n'VehicleMake':'${state.customerData.vehicleMake}',\n'VehicleModel':'${state.customerData.vehicleModel}',\n'TyreSizeType':'${state.customerData.tyresType}',\n'EngineCC':'${state.customerData.engineCC ? state.customerData.engineCC : ""}',\n'Postcode':'${state.customerData.postcode}',\n'Mobile':'${state.customerData.mobile}',\n'Referral':'${state.customerData.Referral}',\n'HireReward':'${state.hireRewardQuestions.firstQuestion=='Yes'?'0':'1'}',\n'MarketingApproved':'${state.hireRewardQuestions.emailReceiving=='Yes'?'1':'0'}',\n'Town':'${state.customerData.town}',\n'RegistrationDate':'${moment(state.customerData.registrationDate).format("DD/MM/YYYY")}',\n'VehiclePurchaseDate':'${moment(state.customerData.dateOfPurchase).format("DD/MM/YYYY")}',\n'EligibilityCar':${JSON.stringify(eligibility)}}`
      );


      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/GetQuote`,
          requestOptions
        );
        const data = await response.json();
        const { quoteId, code, status } = data;

        if(( code && code === "Success")||(status && !code & status === "Success")) {
            data.products.forEach(product => {
  
            product.logo = element.logo;
            product.redirectURL = element.redirectURL
            product.moreInfo = element.moreInfo
            product.quoteId = quoteId
  
          });
  
          dispatch({ type: GET_LANDING_PRODUCTS_ERROR, payload: false})
          dispatch({ type: GET_LANDING_PRODUCTS, payload: data.products })
        }
        else{
          dispatch({ type: GET_LANDING_PRODUCTS_ERROR, payload: true})
          dispatch({type: GET_PRODUCTS_ERROR})
        }
       


      } catch (error) {
        dispatch({ type: GET_LANDING_PRODUCTS_ERROR, payload: true})
      };

    });



  }
  const getQuote = async () => {

    dispatch({ type: GET_PRODUCTS_BEGIN });

    // reinitialize products / quoteid

    dispatch({ type: REINITIALIZE_PRODUCTS });

    finIdd()

    let myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=5tnbx0wsv0qzqaf4akfajpps");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    let formdata = new FormData();

    //vehicle purchase date to be changed with actual value!!!!

    formdata.append(
      "data",
      `{\n'IgnoreFilters':'false',\n'ProviderId':'${process.env.GATSBY_PROVIDER_ID}',\n'RegNo':'${state.customerData.regNo.toUpperCase()}',\n'Mileage':'${state.customerData.mileage}',\n'Price':'${state.customerData.vehicleValue}',\n'FirstName':'${state.customerData.firstname ? state.customerData.firstname : "-"}',\n'Surname':'${state.customerData.lastname ? state.customerData.lastname : "-"}',\n'Address1':'${state.customerData.address}',\n'Email':'${state.customerData.email ? state.customerData.email : "TestEmail"}',\n'VehicleMake':'${state.customerData.vehicleMake}',\n'VehicleModel':'${state.customerData.vehicleModel}',\n'TyreSizeType':'${state.customerData.tyresType}',\n'EngineCC':'${state.customerData.engineCC != null ? state.customerData.engineCC : "0"}',\n'Postcode':'${state.customerData.postcode}',\n'Mobile':'${state.customerData.mobile}',\n'HireReward':'${state.hireRewardQuestions.firstQuestion}',\n'Town':'${state.customerData.town}',\n'RegistrationDate':'${moment(state.customerData.registrationDate).format("DD/MM/YYYY")}',\n'VehiclePurchaseDate':'${moment(state.customerData.dateOfPurchase).format("DD/MM/YYYY")}'}`
    );

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/GetQuote`,
        requestOptions
      );
      const data = await response.json();
      const { quoteId, code, status } = data;



      if(( code && code === "Success")||(status && !code & status === "Success")) {
        let products;
        {
          if (
            state.hireRewardQuestions.secondQuestion === false &&
            state.hireRewardQuestions.thirdQuestion === false
          ) {
            products = data.products.filter(
              (item) =>
                item.Origin !== "Finance Gap" && item.Origin !== "Return to Invoice"
            );

          }
          else {
            products = data.products;
          }
          dispatch({ type: GET_PRODUCTS_SUCCESS, payload: { products, quoteId } })

          if (typeof window !== "undefined") {
            sessionStorage.setItem(
              "products",
              JSON.stringify({
                products: products,
                quoteId: quoteId,
              })
            );
          }
          dispatch({ type: UPDATE_QUOTE_ID, payload: quoteId })

        }
        return code

      }
    } catch (error) {
      console.log(error)
    };
  }


  const searchRegNo = async () => {
    try {

      if (state.customerData.regNo) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };


        //here start loading animation
        dispatch({ type: SEARCH_REG_NO_IN_PROGRESS, payload: true });

        //make the fetch
        const response = await fetch(
          `${process.env.GATSBY_ENTER_VOUCHER}Home/SearchRegNo?regNo=${state.customerData.regNo.toUpperCase()}`,
          requestOptions
        );


        //after fetch is complete stop the animation
        dispatch({ type: SEARCH_REG_NO_IN_PROGRESS, payload: false });

        //process the response data
        const data = await response.json();
        const {
          VehicleMake,
          VehicleModel,
          TyreSizeType,
          EngineCC,
          VehicleRegistrationDate,
          DriveAxle,
        } = data;

        let dateSubstring = '';

        if(VehicleRegistrationDate){
           dateSubstring = Number(
            VehicleRegistrationDate.substring(6, VehicleRegistrationDate.length - 2)
          );
        }
        
        dispatch({
          type: SET_FETCHED_VEHICLE_DETAILS,
          payload: {
            VehicleMake,
            VehicleModel,
            TyreSizeType,
            EngineCC,
            dateSubstring,
            DriveAxle
          },
        });
      }

    } catch (error) {
      dispatch({ type: SEARCH_REGNO_ERROR });
    }
  };

  const searchPostcode = async () => {
    try {

      if (state.customerData.postcode) {

        var myHeaders = new Headers();
        // myHeaders.append("Cookie", "ASP.NET_SessionId=3sfpw5ykmlqtwu3pjbjhetu2");
        myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        //here start loading animation
        dispatch({ type: SEARCH_POSTCODE_IN_PROGRESS, payload: true });

        //make the fetch
        const response = await fetch(
          `${process.env.GATSBY_ENTER_VOUCHER}Home/SearchPostcode?postcode=${state.customerData.postcode.toUpperCase()}`,
          requestOptions
        );

        //after fetch is complete stop the animation
        dispatch({ type: SEARCH_POSTCODE_IN_PROGRESS, payload: false });

        //process the response data
        const data = await response.json();

        const address = Object.values(data);

        dispatch({ type: SET_ADDRESS_DETAILS, payload: { address } });

      }
      else {

        dispatch({ type: SET_ADDRESS_DETAILS, payload: [] });

      }


    } catch (error) {
      console.log(error)
    }
  };

  //fetch to get the questions

  const checkDemandandNeeds = async () => {
    dispatch({ type: GET_QUESTIONS_BEGIN });
    dispatch({ type: DECREASE_CHECKED_QUESTIONS });

    var myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=j3e1skklwsjqiudyjopilixl");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let Ids = state.ids.join(",");
    let QuoteId = state.quoteId;

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/CheckDemandAndNeeds?quoteId=${QuoteId}&ids=${Ids}`,
        requestOptions
      );
      const data = await response.json();
      const { questions, premiumFundingDetails, co2Emission, code } = data;

      let removeInvert = questions.filter((question) => !question.Invert);

      // delete questions which contains 'hire and reward' if firstQuestion is false

      if (!state.hireRewardQuestions.firstQuestion) {

        removeInvert = removeInvert.filter((question) => !question.Question.toLowerCase().includes("hire and reward"));
      }

      sessionStorage.setItem("questions", JSON.stringify(removeInvert));
      sessionStorage.setItem("premiumFundingDetails", JSON.stringify(premiumFundingDetails));
      dispatch({
        type: GET_QUESTIONS_SUCCESS,
        payload: { removeInvert, premiumFundingDetails, co2Emission },
      });

      return code

    } catch (error) {
      console.log(error)
    }
  };

  const toggleOption = (questions, id, checked) => {
    return questions.map((option) =>
      option.PPPIds === id ? { ...option, checked } : option
    );
  };


  const changeList = (PPPIds, checked) => {
    dispatch({
      type: CHECK_QUESTION,
      payload: toggleOption(state.checkedList, PPPIds, checked),
    });
  };

  const selectTypeOfPayment = (event) => {
    // const radioValue = event.target.value;
    // console.log(event.target,".....type of payment")
    let radioValue;
    if (event.target.dataset.type) {
      radioValue = event.target.dataset.type;
    } else {
      radioValue = event.target.value;
    }
    dispatch({ type: SET_TYPE_OF_PAYMENT, payload: radioValue });
  };

  const resetQuestionsAddCart = async () => {

    await checkDemandandNeeds();
    dispatch({ type: DELETE_STRIPE_CLIENT_SECRET });
    dispatch({ type: SET_QUESTIONS_CHECKED, payload: 1 });

  }
  const addToCart = async (product) => {
    dispatch({ type: ADD_TO_CART, payload: { product } });

  };
  //if the user adds an insurance but then changes his mind and closes the product component, remove that insurance from the cart

  const deselectProduct = async (origin) => {
    dispatch({ type: DESELECT_PRODUCT, payload: origin });
  };

  //function for changing date in datePicker

  const handleDate = (date, id) => {
    dispatch({ type: CHANGE_SELECTED_PRODUCTS_DATE, payload: { date, id } });
  };
  const handleDateOfBirth = (date) => {
    dispatch({ type: SET_BIRTHDAY, payload: date });
  };

  const getPurchaseDate = (date) => {
    dispatch({ type: GET_PURCHASE_DATE, payload: date });
  };

  const getRegistrationDate = (date) => {
    dispatch({ type: GET_REGISTRATION_DATE, payload: date });
  };


  useEffect(() => {
    //update ids array every time cart changes and SelectedProducts
    dispatch({ type: UPDATE_IDS_ARRAY })
  }, [state.cart])

  useEffect(() => {

    // when user change the hire questions on the first step, call getQuote() function
    if (state.products && state.checkoutStep === 0) {

      getQuote();
      clearCart();

    }

  }, [state.hireRewardQuestions.firstQuestion, state.hireRewardQuestions.secondQuestion, state.hireRewardQuestions.thirdQuestion])

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("idd", state.finIDDfetchResult);
    }
  }, [state.finIDDfetchResult]);

  useEffect(() => {

    if (typeof window !== "undefined") {

      sessionStorage.setItem("cart", JSON.stringify(state.cart))
    }
  }, [state.cart]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("complaints", state.finComplaints);
    }
  }, [state.finComplaints]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("cancellation", state.finCancellation);
    }
  }, [state.finCancellation]);



  useEffect(() => {
    //start regNo fetch so when the user enters reg number
    if (state.customerData.regNo.replace(/\s/gm, '').length === 7) {
      searchRegNo()
    }
  }, [state.customerData.regNo])

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("checkoutStep", state.checkoutStep);
    }
  }, [state.checkoutStep]);

  useEffect(() => {

    if (typeof window !== "undefined") {
      sessionStorage.setItem("formData", JSON.stringify(state.customerData))
    }
  }, [state.customerData]);

  //remove insurance from cart
  const removeItem = async (id) => {
    dispatch({ type: REMOVE_CART_ITEM, payload: id });
  };

  const removeSession = async () => {

    sessionStorage.clear()


  }

  //increase/decrease step in the checkout process

  const increaseStep = () => {
    dispatch({ type: INCREASE_STEP });
  };

  const increaseQuestionChecked = () => {

    dispatch({ type: INCREASE_CHECKED_QUESTIONS });

  }
  const resetQuestionChecked = () => {

    dispatch({ type: SET_QUESTIONS_CHECKED, payload: 1 });
    dispatch({ type: DELETE_STRIPE_CLIENT_SECRET });

  }

  const paymentDecreaseStep = () => {

    dispatch({ type: DELETE_STRIPE_CLIENT_SECRET });

    if (!state.premiumFundingDetails.ShowPaymentTypeSelector) {

      dispatch({ type: DECREASE_STEP });

    }
  }
  const decreaseStep = () => {

    if (state.checkoutStep == 2) {


      dispatch({ type: DELETE_STRIPE_CLIENT_SECRET });
      dispatch({ type: DECREASE_CHECKED_QUESTIONS });
      dispatch({ type: SET_TYPE_OF_PAYMENT, payload: "fullPayment" });
      dispatch({ type: UNCHECK_ALL_QUESTIONS });
    }

    dispatch({ type: DECREASE_STEP });


  };

  const finIdd = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.GATSBY_FIN_IDD_KEY);

    let raw = JSON.stringify({
      OrganisationLogo:
        MyTheme.externalLogo,
      OrganisationLogoAlt: MyTheme.pageTitle,
      AddressLine_1: "V12 b Merlin Park,",
      AddressLine_2: "Ringtail Road,",
      AddressLine_3: "Burscough, L40 8JY",
      FCANumber: "732530",
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.GATSBY_FIN_API_IDD,
        requestOptions
      );
      const data = await response.text();

      dispatch({ type: SET_FIN_IDD_RESULT, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  const complaintsProcedure = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.GATSBY_FIN_DEFAULT_KEY);

    let raw = JSON.stringify({
      OrganisationLogo:
        MyTheme.externalLogo,
      OrganisationLogoAlt: MyTheme.pageTitle,
      AddressLine_1: "V12 b Merlin Park,",
      AddressLine_2: "Ringtail Road,",
      AddressLine_3: "Burscough, L40 8JY",
      FCANumber: "732530",
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.GATSBY_FIN_COMPLAINTS_API,
        requestOptions
      );
      const data = await response.text();

      dispatch({ type: SET_FIN_COMPLAINTS, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
  const cancellationAndClaimProcess = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.GATSBY_FIN_DEFAULT_KEY);

    let raw = JSON.stringify({
      OrganisationLogo:
        MyTheme.externalLogo,
      OrganisationLogoAlt: MyTheme.pageTitle,
      AddressLine_1: "V12 b Merlin Park,",
      AddressLine_2: "Ringtail Road,",
      AddressLine_3: "Burscough, L40 8JY",
      FCANumber: "732530",
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.GATSBY_FIN_CANCELLATION_API,
        requestOptions
      );
      const data = await response.text();

      dispatch({ type: SET_FIN_CANCELLATION, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    complaintsProcedure();
    cancellationAndClaimProcess();

    //   if(typeof window !== 'undefined' && window.sessionStorage.getItem('purchaseCode')) {
    //   PurchaseCode = sessionStorage.getItem('purchaseCode');
    // }

    // sessionStorage.setItem('purchaseCode', state.purchaseCode)
  }, []);

  //select type of payment
  const selectTypeOfPaymentFlag = (event) => {

    const radioValue = event.target.value;
    dispatch({ type: SET_TYPE_OF_PAYMENT, payload: radioValue });
  };

  const handlePreferredPaymentDay = (date) => {
    dispatch({ type: SET_PAYMENT_DAY, payload: date });
  };
  //send the user to step 0 if clicks on sidebar item

  const changeStep = (value) => {
    dispatch({ type: CHANGE_STEP_VALUE, payload: value })
  }

  const resetQuestions = () => {

    dispatch({ type: SET_TYPE_OF_PAYMENT, payload: "fullPayment" });
    dispatch({ type: DECREASE_CHECKED_QUESTIONS })
    dispatch({ type: UNCHECK_ALL_QUESTIONS });
  };

  const changeRegistrationDate = (date) => {
    dispatch({ type: UPDATE_REGISTRATION_DATE, payload: date })
  }

  const checkBankData = async () => {
    dispatch({ type: START_CHECK_VALIDATION });

    var myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=ythit2zw2scv2se0vb2hqzqp");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}/Home/BankValidationSeach?accountNumber=${state.directDebitDetails.accountNo}&sortCode=${state.directDebitDetails.sortCode}`,
        requestOptions
      );

      const data = await response.json();

      if (data.code === "Failed") {
        dispatch({ type: FAILED_BANK_VALIDATION_REQUEST });
      } else if (data.code === "Success") {
        dispatch({ type: CHECK_BANK_DETAILS, payload: data });
      }
    } catch (error) {
      dispatch({ type: FAILED_BANK_VALIDATION_REQUEST });
    }
  };

  //complete and pay order

  const completeQuoteOrder = async () => {
    dispatch({ type: DELETE_EXISTING_STRIPE_DATA });
    dispatch({ type: COMPLETE_ORDER_BEGIN });

    let myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=5tnbx0wsv0qzqaf4akfajpps");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    let formdata = new FormData();

    if (state.typeOfPayment === "fullPayment") {
      formdata.append(
        "data",
        `{\n'QuoteId':'${state.quoteId}',\n'SelectedProducts':${JSON.stringify(
          state.selectedProducts
        )},\n'PaymentType': '${state.typeOfPayment}',\n'PurchaseCode':'${state.purchaseCode
        }',\n'co2Emission':'${state.co2Option}'}`
      );
    } else if (state.typeOfPayment === "directDebit") {
      formdata.append(
        "data",
        `{\n'QuoteId':'${state.quoteId}',\n'SelectedProducts':${JSON.stringify(
          state.selectedProducts
        )},\n'DOB': '${state.dateOfBirth}',\n'PaymentType': '${state.typeOfPayment
        }',\n'TransactionId':'test',\n'AmountPaid': '${state.premiumFundingDetails.MinDepositAmount
        }',\n'AccountHolderFirstname': '${state.directDebitDetails.firstname
        }',\n'AccountHolderSurname':'${state.directDebitDetails.surname
        }',\n'AccountNumber':'${state.directDebitDetails.accountNo
        }',\n'SortCode':'${state.directDebitDetails.sortCode}',\n'BankName':'${state.directDebitDetails.bankName
        }',\n'PreferredPaymentDate':'${state.directDebitDetails.preferredPaymentDate
        }',\n'PurchaseCode':'${state.purchaseCode}',\n'co2Emission':'${state.co2Option
        }'}`
      );
    }

    let requestOptions = {
      method: "POST",
      // headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/CompleteQuoteOrder`,
        requestOptions
      );
      const data = await response.json();
      const { purchaseCode, code, stripeClientSecret } = data;

      sessionStorage.setItem("purchaseCode", purchaseCode);
      dispatch({
        type: GET_PURCHASE_CODE,
        payload: { purchaseCode, stripeClientSecret, code },
      });

      if (state.typeOfPayment === "directDebit" && state.premiumFundingDetails.MinDepositAmount === 0) {

        dispatch({
          type: CHANGE_STEP_VALUE,
          payload: 3,
        });

        sessionStorage.clear();
      }
      else {

        dispatch({ type: SET_TYPE_OF_PAYMENT, payload: "fullPayment" });

      }

      return code;
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <NewCheckoutContext.Provider
      value={{
        ...state,
        updateCustomerDetails,
        getQuoteWithDefaultCustomerValues,
        searchRegNo,
        addToCart,
        selectTypeOfPayment,
        deselectProduct,
        handleDateOfBirth,
        handleDate,
        handleDirectDebit,
        removeItem,
        updateAddress,
        updateLandingFilters,
        increaseStep,
        decreaseStep,
        clearCart,
        checkBankData,
        searchPostcode,
        getQuote,
        sortLandingProducts,
        getRegistrationDate,
        getPurchaseDate,
        increaseQuestionChecked,
        resetQuestionChecked,
        finIdd,
        deleteClientSecret,
        checkDemandandNeeds,
        changeList,
        updateCustomerFromUrl,
        removeSession,
        selectTypeOfPaymentFlag,
        handlePreferredPaymentDay,
        resetQuestionsAddCart,
        changeStep,
        resetQuestions,
        updateHireRewardQuestions,
        setSortOption,
        setOrderOption,
        completeQuoteOrder,
        getQuoteLandingPage,
        paymentDecreaseStep,
        addProductSidebar,
        changeRegistrationDate
      }}
    >
      {children}
    </NewCheckoutContext.Provider>
  );
}

export const useNewCheckoutContext = () => {
  return useContext(NewCheckoutContext);
};
