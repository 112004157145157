import moment from "moment"
import {
  UPDATE_FIELD,
  INCREASE_STEP,
  DECREASE_STEP,
  GET_ORIGIN,
  ADD_TO_CART,
  CLEAR_CART,
  REMOVE_CART_ITEM,
  COUNT_CART_TOTALS,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PURCHASE_DATE,
  GET_REGISTRATION_DATE,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_BEGIN,
  COMPLETE_ORDER_BEGIN,
  GET_PURCHASE_CODE,
  SET_FETCHED_VEHICLE_DETAILS,
  SET_SELECTED_PRODUCTS,
  CHANGE_SELECTED_PRODUCTS_DATE,
  SET_TYPE_OF_PAYMENT,
  SET_BIRTHDAY,
  SAVE_TOKEN,
  REFRESH_IDS,
  SEARCH_REG_NO_IN_PROGRESS,
  DELETE_EXISTING_STRIPE_DATA,
  RETURN_TO_PREVIOUS_STATE_FROM_PAYMENT,
  DISABLE_REACT_DATE_PICKER,
  CHECK_QUESTION,
  UNCHECK_ALL_QUESTIONS,
  SEARCH_POSTCODE_IN_PROGRESS,
  SET_ADDRESS_DETAILS,
  UPDATE_ADDRESS,
  CHOOSECO2,
  UPDATE_PERSONAL_FUNDING_DETAILS,
  UPDATE_HIRE_AND_REWARD_QUESTIONS,
  START_CHECK_VALIDATION,
  CHECK_BANK_DETAILS,
  SET_PAYMENT_DAY,
  FAILED_BANK_VALIDATION_REQUEST,
  SET_FIN_IDD_RESULT,
  SET_FIN_COMPLAINTS,
  SET_FIN_CANCELLATION,
  UPDATE_PROGRESS_BAR_INDEX,
  DELETE_STRIPE_CLIENT_SECRET,
  OPEN_MODAL,
  CLOSE_MODAL,
  INIT_BASKET_REDIRECT,
  CHANGE_STEP_VALUE
} from "../actions/actions"



const checkout_reducer = (state, action) => {
  if (action.type === INCREASE_STEP) {
    return { ...state, formStep: state.formStep + 1, isStepChanged:true }
  }
  if (action.type === DECREASE_STEP) {
    return { ...state, formStep: state.formStep - 1, isStepChanged:true }
  }


  if (action.type === ADD_TO_CART) {
    const { product } = action.payload

    if (product) {

     

    const tempItem = state.cart.find(item => item.Origin === product.Origin) // check if an item with the same origin exists in the cart
    const rtiItem = state.cart.find(item => item.Origin === "Return to Invoice") 
    const gapItem = state.cart.find(item => item.Origin === "Finance Gap")

    

      
  if (tempItem) {
    const tempCart = state.cart.map(cartItem => {
      if (cartItem.Origin === product.Origin) {
        let replace = state.cart.findIndex(
          item => item.Origin === cartItem.Origin
        )

        
        let newItem
        if (replace !== -1) {
          
          newItem = state.cart[replace] = product
        }

        return { ...newItem }
      } else {
        return cartItem
      }

      
    })

    return { ...state, cart: tempCart }

        
  } 
  
  if(product.Origin === 'Return to Invoice' && gapItem) {
       let replace = state.cart.findIndex(item => item.Origin === "Finance Gap")
       if (replace !== -1) {
         state.cart[replace] = product
       }
       return {...state}
      } if(product.Origin === "Finance Gap" &&rtiItem) {
       let replace = state.cart.findIndex(item => item.Origin === 'Return to Invoice')
       if (replace !== -1) {
         state.cart[replace] = product
       }
       return {...state}
      } 
      
      else {

     

        const newItem ={
          Description: product.Description,
          Discount: product.Discount,
          KeyFacts: product.KeyFacts,
          Origin: product.Origin,
          PPPId: product.PPPId,
          Price: product.Price,
          PriceRRP: product.PriceRRP,
          ProducGroupId: product.ProducGroupId,
          ProducGroupTitle: product.ProducGroupTitle,
          Title: product.Title,
          claim_limit: product.claim_limit,
          months_of_cover: product.months_of_cover,
        }

       

        return { ...state, cart: [...state.cart, newItem],ids:[...state.ids, newItem.PPPId] }
      }

      
    } else {
      return { ...state, purchaseError: true }
    }

    
  }

  if (action.type === CLEAR_CART) {
    return { ...state, cart: [], ids: [], questions:[] }
  }

  if (action.type === REMOVE_CART_ITEM) {
    const tempCart = state.cart.filter(item => item.PPPId !== action.payload)
    const filterIds = state.ids.filter(item => item !== action.payload)
    const filterSelectedProducts = state.SelectedProducts.filter(item => item.PPPId !== action.payload)
    return { ...state, cart: tempCart, ids: filterIds, SelectedProducts : filterSelectedProducts }
  }

  if (action.type === COUNT_CART_TOTALS) {
    let total = 0
    for (let item of state.cart) {
      const { Price } = item
      total += Price
    }
    
    return { ...state, total_amount: total }
  }

  if (action.type === UPDATE_FIELD) {
    const { value, name } = action.payload
    return {
      ...state,
      details: {
        ...state.details,
        [name]: value,
      },
    }
  }
  if (action.type === UPDATE_ADDRESS ) {
    const  {value, city, address, postcode}  = action.payload
    

    return {
      ...state,
        fullAddress: value,
       
        details:{
          ...state.details,
          city,
          postcode,
          address,
        }
      
    }
  }

  if (action.type === GET_ORIGIN) {

    return { ...state, displayFirst: action.payload, isDisplayedFirst: true }

  }

  if(action.type === GET_PRODUCTS_SUCCESS) {

    const {filteredDependingOnHireReward, quoteId, code} = action.payload
    return {...state, fetchResult: filteredDependingOnHireReward, quoteId: quoteId, fetchCode: code}

  }

  if(action.type === GET_PRODUCTS_ERROR) {
    return {...state, fetchError: true}
  }

  if(action.type === GET_PRODUCTS_BEGIN) {
    return {...state, loading: true}
  }
  if(action.type === GET_QUESTIONS_BEGIN) {
    return {...state, isQuestionsLoading: true}
  }


  if(action.type === GET_QUESTIONS_SUCCESS) {
    const {removeInvert, premiumFundingDetails, co2Emission} = action.payload
   
    return {...state, questions: removeInvert, fetchPremiumFundingDetails: premiumFundingDetails, co2Emissions: co2Emission, isQuestionsLoading:false, checkedList: state.cart.length !== 0 ? removeInvert.map((option) => ({
            ...option,
            checked: false
        })) : []}
  }

  if(action.type === CHECK_QUESTION) {
    return {...state, checkedList: action.payload}
  }

  if(action.type === UNCHECK_ALL_QUESTIONS) {
    return {...state, checkedList: state.cart.length !== 0? state.questions.map((option) => ({
            ...option,
            checked: false
        })) : []}
  }

  if(action.type === COMPLETE_ORDER_BEGIN) {
    return {...state, purchaseLoading: true}
  }
  if(action.type === GET_PURCHASE_CODE) {
    const {purchaseCode, code, stripeClientSecret} = action.payload
    return {...state, purchaseCode: purchaseCode, stripeStatusCode:code, stripeClientSecret, datePickerFlag:true, purchaseLoading: false}
  }

    if(action.type === SET_FETCHED_VEHICLE_DETAILS) {
      const {VehicleMake, VehicleModel, EngineCC, dateSubstring, AlloyWheelType} = action.payload

      let date = new Date()

    return {...state, details:{...state.details, vehicleMake:VehicleMake, vehicleModel:VehicleModel, engineCc: EngineCC, firstRegistrationDate: new Date(dateSubstring ), tyresType: AlloyWheelType, firstRegistrationDateFormatted:moment(dateSubstring).format('DD/MM/YYYY'), dateOfPurchaseFormatted:moment(date).format('DD/MM/YYYY')}}
  }

  if(action.type === GET_PURCHASE_DATE) {
   
    return {...state, details:{...state.details, dateOfPurchase:action.payload, dateOfPurchaseFormatted:moment(action.payload).format('DD/MM/YYYY')}}
  }

  if(action.type === GET_REGISTRATION_DATE) {
    
    return {...state, details:{...state.details, firstRegistrationDate:action.payload, firstRegistrationDateFormatted:moment(action.payload).format('DD/MM/YYYY')}}
  }

  if(action.type === SET_SELECTED_PRODUCTS) {
    const seletedProd = action.payload;
    const formattedDate =  seletedProd.map((object) => {
      const {StartDate, PPPId} = object;
      
      return {PPPId, StartDate: moment(StartDate).format('DD/MM/YYYY')}
    })
    return {...state, SelectedProductsUnformatted:action.payload, SelectedProducts: formattedDate }
  }
  if(action.type === CHANGE_SELECTED_PRODUCTS_DATE) {
    const {date, id} = action.payload;
    const selectedProducts = state.SelectedProductsUnformatted
    let updateDateIndex = selectedProducts.findIndex(item => item.PPPId === id)
    selectedProducts[updateDateIndex].StartDate = date
  
   const formattedDate =  selectedProducts.map((object) => {
      const {StartDate, PPPId} = object;
     
      return {PPPId, StartDate: moment(StartDate).format('DD/MM/YYYY')}
    })
    return {...state, SelectedProductsUnformatted: [...state.SelectedProductsUnformatted], SelectedProducts: formattedDate }
  }

  if(action.type === SET_TYPE_OF_PAYMENT) {
    return {...state, typeOfPayment: action.payload}
  }

  if(action.type === SET_BIRTHDAY) {
    return {...state, dateOfBirthUnformatted: action.payload, dateOfBirth:  moment(action.payload).format('DD/MM/YYYY')}
  }
  if(action.type === SET_PAYMENT_DAY) {
    return {...state,directDebitDetails:{...state.directDebitDetails, preferredPaymentDateUnformatted: action.payload, preferredPaymentDate:  moment(action.payload).format('DD/MM/YYYY')} }
  }
  if(action.type === SAVE_TOKEN) {
    return {...state, stripeTokenId: action.payload}
  }
  if(action.type === REFRESH_IDS) {
   const newIds = state.cart.map((item) => item.PPPId)
    
    return {...state, ids: newIds}
  }

  if(action.type === DELETE_EXISTING_STRIPE_DATA) {
    return {...state, stripeTokenId:'', stripeStatusCode:'', datePickerFlag:false}
  }



  //shows an animation while the search fetch is loading
  if(action.type === SEARCH_REG_NO_IN_PROGRESS){
    return {...state, searchRegNoInProgress: action.payload}
  }
  if(action.type === SEARCH_POSTCODE_IN_PROGRESS){
    return {...state, searchPostcodeInProgress: action.payload}
  }
  if(action.type === SET_ADDRESS_DETAILS){
    return {...state, addressArray: action.payload}
  }


  if(action.type === RETURN_TO_PREVIOUS_STATE_FROM_PAYMENT) {
    return {...state, returnToPrevious:true}
  }

  if(action.type === DISABLE_REACT_DATE_PICKER) {
    return {...state, datePickerFlag:false}
  }

  if(action.type === CHOOSECO2) {
    return {...state, co2Option:action.payload}
  }
  if(action.type === UPDATE_PERSONAL_FUNDING_DETAILS) {
    const {value, name} = action.payload
    return {...state,directDebitDetails:{...state.directDebitDetails, [name]:value} }
  }
  if(action.type ===  UPDATE_HIRE_AND_REWARD_QUESTIONS) {
    const {value, name} = action.payload
    return {...state,hireRewardQuestions:{...state.hireRewardQuestions, [name]:value} }
  }
  if(action.type ===  START_CHECK_VALIDATION) {
    
    return {...state, directDebitLoading: true }
  }
  if(action.type ===  CHECK_BANK_DETAILS) {
  
    const {code, result} = action.payload
   

    return {...state, directDebitDetails:{...state.directDebitDetails, bankName: result.Bank, bankPostcode: result.ContactPostcode, bankAddress1:result.ContactAddressLine1, bankAddress2:result.ContactAddressLine2,bankTown: result.ContactPostTown}, directDebitLoading: false, bankValidationCode:code }
    
  }


  if(action.type === FAILED_BANK_VALIDATION_REQUEST) {
    return {...state, directDebitLoading:false, directDebitError:true}
  }

  if(action.type ===  SET_FIN_IDD_RESULT) {
    return {...state, finIDDfetchResult: action.payload}
  }
  if(action.type ===  SET_FIN_COMPLAINTS) {
    return {...state, finComplaints: action.payload}
  }
  if(action.type ===  SET_FIN_CANCELLATION) {
    return {...state, finCancellation: action.payload}
  }
  if(action.type === UPDATE_PROGRESS_BAR_INDEX) {
    return { ...state, formStep: action.payload}
  }
  if(action.type === DELETE_STRIPE_CLIENT_SECRET) {
    return { ...state, stripeClientSecret: ''}
  }
  if(action.type === OPEN_MODAL) {
    return { ...state, directDebitModal:true }
  }
  if(action.type === CLOSE_MODAL) {
    return { ...state, directDebitModal: false}
  }
  if(action.type === CHANGE_STEP_VALUE) {
    return { ...state, formStep:action.payload}
  }


  if(action.type === INIT_BASKET_REDIRECT) {
    let premiumFunding
    let quoteDetails;
    if (typeof window !== "undefined" && window.sessionStorage.getItem("premiumFundingDetailsInitBasket")) {
      premiumFunding = JSON.parse(sessionStorage.getItem("premiumFundingDetailsInitBasket"));
    }

      const removeInvert = premiumFunding.questions.filter((question) => !question.Invert);

    if(typeof window !== "undefined" && window.sessionStorage.getItem("quoteDetails")) {
      quoteDetails = JSON.parse(sessionStorage.getItem("quoteDetails"))
    }
    

    return { ...state,  questions: removeInvert,quoteId: premiumFunding.quoteId, fetchPremiumFundingDetails: premiumFunding.premiumFundingDetails, co2Emissions: premiumFunding.co2Emission, isQuestionsLoading:false, checkedList:  removeInvert.map((option) => ({
            ...option,
            checked: false
        })) , cart:quoteDetails.products}
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default checkout_reducer
